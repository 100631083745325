import type { I18n, Locale } from 'vue-i18n';
import { createI18n } from 'vue-i18n';
import { DEFAULT_LANGUAGE } from '@@home/utils/constants';

function loadLanguage(prefix: string) {
  const lang = import.meta.glob("@@home/locales/*.y(a)?ml", { eager: true }) as Record<string, any>;
  const re = new RegExp(`/${prefix}\.`, 'i');

  const key = Object.keys(lang).find(item => item.match(re)) as string;

  return lang[key].default;
}

export const localesConfigs = {
  'zh-CN': {
    ...loadLanguage('zh-CN'),
  },
  'en-US': {
    ...loadLanguage('en-US'),
  },
};

let i18n: I18n<NonNullable<unknown>, NonNullable<unknown>, NonNullable<unknown>, Locale, false>;

export const getInstanceAndSetLanguage = (language = DEFAULT_LANGUAGE) => {
  if (!i18n) {
    i18n = createI18n({
      legacy: false,
      locale: language,
      fallbackLocale: {
        'en-US': [DEFAULT_LANGUAGE],
      },
      messages: localesConfigs,
    })
  }
  else {
    i18n.global.locale.value = language;
  }

  return i18n;
}

export const getInstance = () => {
  return i18n;
}
