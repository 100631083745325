import type { App, Plugin } from 'vue';
import ClientOnly from '@duannx/vue-client-only';
import { useAppStore } from '@@home/stores/app';
import { getInstanceAndSetLanguage } from './i18n';

export default {
  install(app: App) {
    const appStore = useAppStore();
    const i18n = getInstanceAndSetLanguage(appStore.language) as Plugin;

    app.use(i18n);

    app.component('ClientOnly', ClientOnly);
  },
};
